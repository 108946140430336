import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'
import { find, isEmpty } from '../../lodash'
import { FormattedMessage } from 'react-intl'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Box, Currency, Heading, HeadingInfoBox, Image, LazyLoad, Link, NoImage, Text } from '../../elements'
import { HeadLine, Loading } from '../../components'
import { TranslatedMetaLoader, TranslatedTitleLoader } from '../../modules'
import { getIncludedData } from '../../utils'
import images from '../../../assets/images/newcb'
import client from '../../api/api'

export const ProductsPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState([])

  useEffect(() => {
    getProducts()
  }, [])

  const getProducts = async () => {
    const response = await client.products.list(
      {},
      { include: 'images' },
    )

    if (response.isSuccess()) {
      const { data, included } = response.success()

      const productsWithImages = data.map(product => Object.assign(
        {},
        product,
        { images: getIncludedData(included, product.relationships.images.data[0]?.id, 'image') },
      ))

      setProducts(productsWithImages)
      setIsLoading(false)
    }
  }

  if (isLoading)
    return <Loading />

  return (
    <>
      <HeadLine />

      <Container className="mt-3 mb-5">

        <TranslatedTitleLoader titleId="pageTitleProducts" />

        <TranslatedMetaLoader
          metaArray={[
            { content: 'metaTextProducts', name: 'description' },
            { content: 'metaTextProducts', property: 'og:description' },
            { content: 'pageTitleProducts', property: 'og:title' },
          ]}
        />

        <Row className="products-page">
          <Col md="12">
            <CustomHeadingBox color="white" dark fontWeight="normal" mb={30} mt={-150} mx="auto" px={60} width={400}>
              <Text as="h4" color="white" fontSize="22px">
                <FormattedMessage id="chooseDiaper" />
              </Text>
              <Box lineHeight="18px" px={[0, 3]} textAlign="center">
                <FormattedMessage id="metaTextProducts" />
              </Box>
            </CustomHeadingBox>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          {products.map(item => (
            <Col key={item.id} lg={4} md={6} sm={12} style={{ marginBottom: '65px' }} xl={4}>
              <StyledCard bg="#fff">
                <Box p={20}>
                  {isEmpty(item.images)
                    ? <NoImage />
                    : (
                      <LazyLoad
                        boxStyle={{
                          aspectRatio: '1',
                          background: '#eee',
                          borderRadius: '20px',
                          width: '100%',
                        }}
                        imageSrc={find(item.images[0].attributes.styles, { size: '600x600>' }).url}
                      >
                        {({ imageSrc }) => (
                          <Image
                            fluid
                            src={imageSrc}
                            style={{ borderRadius: '20px' }}
                          />
                        )}
                      </LazyLoad>

                    )
                  }
                </Box>

                <Card.Body>
                  <Card.Title>
                    <Box alignItems="center" display="flex" flex="1 1 auto" justifyContent="space-between">
                      <Box>
                        <Heading as="h3" fontFamily="Nunito" fontSize={[4, 3]} fontWeight="bold">
                          <Link color="dark" style={{ textDecoration: 'none'}} to={`/product/${item.attributes.slug}`}>
                            {item.attributes.name}
                          </Link>
                        </Heading>
                      </Box>

                      <Box>
                        <Text
                          color="primary.dark"
                          fontSize={5}
                          fontWeight="bold"
                          mb={0}
                          textAlign="right"
                        >
                          <Currency data-test="product-base-price" value={item.attributes.base_price} />
                        </Text>

                      </Box>
                    </Box>

                    <Text fontSize={1} pt={2} textAlign="right">
                      {'+ '}
                      <Currency data-test="product-deposit-price" value={item.attributes.deposit_price} />
                      {' '}
                      <FormattedMessage id="refundableDeposit" />
                    </Text>
                  </Card.Title>

                  <Card.Text as="div">
                    <Heading as="h3" color="black" fontFamily="Nunito" fontSize={[3, 2]} fontWeight="bold" mb={2} mt={5}>
                      <FormattedMessage id="includes" />
                    </Heading>

                    <Box id="plus-list">
                      <ul>
                        {item.attributes.items.filter(item => item.quantity > 0).map(lineItem => (
                          <li key={lineItem.name}>
                            {lineItem.quantity < 2
                              ? lineItem.name
                              : `${lineItem.quantity} ${lineItem.name}`
                            }
                          </li>
                        ))}
                        <li>
                          <FormattedMessage id="freePickUp" />
                        </li>
                      </ul>
                    </Box>
                  </Card.Text>

                  <Box mb={-20} mt={3} textAlign="center">
                    <StyledLink
                      bg="primary.dark"
                      className="view-item"
                      color="gray.light"
                      mr={0}
                      to={`/product/${item.attributes.slug}`}
                    >
                      <FormattedMessage id="getStarted" />
                    </StyledLink>
                  </Box>
                </Card.Body>
              </StyledCard>
            </Col>
          ))}

          <CompareProduct />
        </Row>
      </Container>
    </>
  )
}

const CompareProduct = () => (
  <Col lg={4} md={8} sm={12} style={{ marginBottom: '65px', position: 'relative' }} xl={4}>
    <Box style={{ left: '20%', position: 'absolute', top: '-25px', width: '60%', zIndex: 2 }}>
      <Image
        src={images.productCompareText}
        width="100%"
      />
    </Box>

    <StyledCard bg="#eee">
      <Box p={20}>
        <Image
          fluid
          src={images.productCompareImage}
          style={{ borderRadius: '20px' }}
        />
      </Box>

      <Card.Body>
        <Card.Title>
          <Box alignItems="center" display="flex" flex="1 1 auto" justifyContent="space-between">
            <Box>
              <Heading as="h3" color="dark" fontFamily="Nunito" fontSize={[4, 3]} fontWeight="bold">
                <FormattedMessage id="disposables" />
              </Heading>
            </Box>

            <Box>
              <Text
                color="dark"
                fontSize={5}
                fontWeight="bold"
                mb={0}
                textAlign="right"
              >
                <Currency data-test="product-base-price" value={2800} />
              </Text>

            </Box>
          </Box>

          <Text fontSize={1} pt={2} textAlign="right">
            <FormattedMessage id="lifetimeCostOfDiapers" />
          </Text>
        </Card.Title>

        <Card.Text as="div">
          <Heading as="h3" color="black" fontFamily="Nunito" fontSize={[3, 2]} fontWeight="bold" mb={2} mt={5}>
            <FormattedMessage id="includes" />
          </Heading>

          <Box id="minus-list">
            <ul>
              <li>
                <FormattedMessage id="productCompare1" />
              </li>
              <li>
                <FormattedMessage id="productCompare2" />
              </li>
            </ul>
          </Box>
        </Card.Text>
      </Card.Body>
    </StyledCard>
  </Col>

)

const CustomHeadingBox = styled(HeadingInfoBox)`
  @media (max-width: 400px) {
    width: 100%;

    background-size: auto;
    background-repeat: round;
    background-position: 0 0;
  }
`

const StyledLink = styled(Link)`
  font-family: 'Urbanist', sans-serif !important;

  ${color}
  ${space}
  
  border-radius: 20px;

  font-weight: 400;

  font-size: 14px;

  padding: 10px 30px;

  line-height: 24px;

  cursor: pointer;

  &:hover {
    ${color};
    text-decoration: underline;
  }
`

const StyledCard = styled(Card)`
  ${color}

  height: 100%;

  /* Block drop shadow */
  border: none;

  margin-top: 20px;

  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
`

export default ProductsPage
